import * as _objectKeys2 from "object-keys";
var _objectKeys = _objectKeys2;
try {
  if ("default" in _objectKeys2) _objectKeys = _objectKeys2.default;
} catch (e) {}
import * as _defineDataProperty2 from "define-data-property";
var _defineDataProperty = _defineDataProperty2;
try {
  if ("default" in _defineDataProperty2) _defineDataProperty = _defineDataProperty2.default;
} catch (e) {}
import * as _hasPropertyDescriptors2 from "has-property-descriptors";
var _hasPropertyDescriptors = _hasPropertyDescriptors2;
try {
  if ("default" in _hasPropertyDescriptors2) _hasPropertyDescriptors = _hasPropertyDescriptors2.default;
} catch (e) {}
var exports = {};
var keys = _objectKeys;
var hasSymbols = typeof Symbol === "function" && typeof Symbol("foo") === "symbol";
var toStr = Object.prototype.toString;
var concat = Array.prototype.concat;
var defineDataProperty = _defineDataProperty;
var isFunction = function (fn) {
  return typeof fn === "function" && toStr.call(fn) === "[object Function]";
};
var supportsDescriptors = _hasPropertyDescriptors();
var defineProperty = function (object, name, value, predicate) {
  if (name in object) {
    if (predicate === true) {
      if (object[name] === value) {
        return;
      }
    } else if (!isFunction(predicate) || !predicate()) {
      return;
    }
  }
  if (supportsDescriptors) {
    defineDataProperty(object, name, value, true);
  } else {
    defineDataProperty(object, name, value);
  }
};
var defineProperties = function (object, map) {
  var predicates = arguments.length > 2 ? arguments[2] : {};
  var props = keys(map);
  if (hasSymbols) {
    props = concat.call(props, Object.getOwnPropertySymbols(map));
  }
  for (var i = 0; i < props.length; i += 1) {
    defineProperty(object, props[i], map[props[i]], predicates[props[i]]);
  }
};
defineProperties.supportsDescriptors = !!supportsDescriptors;
exports = defineProperties;
export default exports;